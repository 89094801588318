<template>
  <v-container>
    <v-overlay v-model="carregandoDados">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-data-table
      :headers="headers"
      :items="programacoesClienteEstruturada.filter(semanaAtual)"
      class="border"
      :disable-sort="true"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Cotas Cadastradas</v-toolbar-title>
          <v-divider class="mx-5" inset vertical></v-divider>
          <v-icon
            v-if="canList"
            title="Atualizar"
            medium
            @click="atualizarEstrutura()"
            >mdi-refresh</v-icon
          >
          <v-divider class="mx-5" inset vertical></v-divider>
          <v-switch
            v-if="canList"
            v-model="details"
            label="Detalhes"
            class="mt-5"
          ></v-switch>
          <v-divider class="mx-5" inset vertical></v-divider>
          <v-switch
            v-if="canList"
            v-model="peso"
            label="Por Peso"
            class="mt-5"
          ></v-switch>
        </v-toolbar>
      </template>
      <template
        v-slot:item.programacao_regiao.programacao_fornecedor.programacao_unidade.terminal.descricao="{
          item,
        }"
      >
        {{
          item.programacao_regiao.programacao_fornecedor.programacao_unidade
            .fornecedor.trade_name
        }}<br />
        {{
          item.programacao_regiao.programacao_fornecedor.programacao_unidade
            .terminal.descricao
        }}
      </template>
      <template
        v-slot:item.programacao_regiao.programacao_fornecedor.programacao_unidade.produto.descricao="{
          item,
        }"
      >
        {{
          item.programacao_regiao.programacao_fornecedor.programacao_unidade
            .produto
            ? item.programacao_regiao.programacao_fornecedor.programacao_unidade
                .produto.descricao
            : 'Nenhum'
        }}<br />
        {{
          item.programacao_regiao.programacao_fornecedor.programacao_unidade
            .sub_produto
            ? item.programacao_regiao.programacao_fornecedor.programacao_unidade
                .sub_produto.descricao
            : ''
        }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-data-table
          :headers="headersDay"
          :items="item.status"
          :disable-sort="true"
          class="border"
          hide-default-footer
        >
          <template v-slot:body="props"
            ><tbody>
              <tr
                v-for="(item, index) in props.items"
                :key="item.descricao"
                v-show="index == 0 || details"
              >
                <td
                  class="d-block d-sm-table-cell"
                  v-for="field in headersDay.slice(0, 1)"
                  :key="field.value"
                >
                  {{ item[field.value] }}
                </td>
                <td
                  class="d-block d-sm-table-cell"
                  v-for="field in headersDay.slice(1)"
                  :key="field.value"
                >
                  <span v-show="peso == true">
                    {{ item[field.value] ? item[field.value].qt_peso : '0' }}
                  </span>
                  <span v-show="!peso == true">
                    {{
                      item[field.value] ? item[field.value].qt_caminhoes : '0'
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </template>
      <template #no-data v-if="!canList">
        <span class="red--text"
          >Você não tem permissão para visualizar as informações desta
          tela</span
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { DAYS, TIME_DAYS } from '@/mixins/days.js'
import { contabilizadores, pesquisa } from '@/mixins/cotas.js'
import { BasicPermissions, Sequences } from '@/utils/permissions'
export default {
  name: 'CotasProgramacaoAgendamento',
  props: ['calendario', 'params'],
  mixins: [contabilizadores, pesquisa],
  data() {
    return {
      peso: false,
      details: false,
      carregandoDados: false,
      headersDay: [
        { text: 'Status', value: 'descricao' },
        { text: 'DOMINGO', value: 'DOMINGO' },
        { text: 'SEGUNDA', value: 'SEGUNDA' },
        { text: 'TERÇA', value: 'TERÇA' },
        { text: 'QUARTA', value: 'QUARTA' },
        { text: 'QUINTA', value: 'QUINTA' },
        { text: 'SEXTA', value: 'SEXTA' },
        { text: 'SÁBADO', value: 'SÁBADO' },
      ],
      headers: [
        { text: 'Transportadora', value: 'transportadora.trade_name' },
        { text: 'Cliente', value: 'programacao_regiao.cliente.trade_name' },
        {
          text: 'Região',
          value: 'programacao_regiao.programacao_fornecedor.regiao.sigla',
        },
        { text: 'Tipo de Frete', value: 'tipo_frete.descricao' },
        {
          text: 'Tipo',
          value:
            'programacao_regiao.programacao_fornecedor.programacao_unidade.tipo_operacao.descricao',
        },
        {
          text: 'Terminal',
          value:
            'programacao_regiao.programacao_fornecedor.programacao_unidade.terminal.descricao',
        },
        {
          text: 'Produto',
          value:
            'programacao_regiao.programacao_fornecedor.programacao_unidade.produto.descricao',
        },
        { text: 'Cotas', value: 'status' },
      ],
      programacoesClienteEstruturada: [],
      estrutura_exemplo: {
        programacao_regiao: null,
        tipo_frete: null,
        transportadora: null,
        status: [
          {
            descricao: 'Cotas Liberadas',
          },
          {
            descricao: 'Cotas Distribuídas',
          },
          {
            descricao: 'Em trânsito',
          },
          {
            descricao: 'Na triagem',
          },
          {
            descricao: 'Atendidas',
          },
          {
            descricao: 'Disponível',
          },
        ],
      },
    }
  },
  computed: {
    ...mapState('patio', ['programacoesCliente']),
    // programacoesClienteEstruturada: function
    permission() {
      return Sequences.CotasTransportadora.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    if (this.canList) {
      this.DAYS = DAYS
      await this.headersDayNow()
      await this.atualizarListaProgramacoesCliente()
      await this.estruturaProgramacoesCliente()
    }
  },
  methods: {
    ...mapActions('patio', ['getProgramacoesCliente']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    carregarDados() {
      this.carregandoDados = true
    },
    finalizarCarregamentoDados() {
      this.carregandoDados = false
    },
    semanaAtual(value) {
      let desta_semana = false
      let dias = this.headersDay.slice(1, 8)
      dias.every(function (dia) {
        if (
          (value.status[0][dia.text]
            ? value.status[0][dia.text].qt_peso ||
              value.status[0][dia.text].qt_caminhoes
            : 0) > 0
        ) {
          desta_semana = true
          return false
        }
        return true
      })
      return desta_semana
    },
    async atualizarListaProgramacoesCliente(params = null) {
      try {
        if (params == null) {
          params = this.params
        }
        let filtros = this.pesquisa(params)
        if (!filtros.data_inicio) {
          filtros.data_inicio = new Date(
            new Date(new Date().setHours(0, 0, 0, 0)).setDate(
              new Date().getDate() - new Date().getDay()
            )
          )
            .toISOString()
            .split('T')[0]
        }
        this.carregarDados()
        await this.getProgramacoesCliente(filtros)
        this.finalizarCarregamentoDados()
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não possui acesso as programações.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },
    headersDayNow() {
      let semana = [
        this.DAYS.DOMINGO,
        this.DAYS.SEGUNDA,
        this.DAYS.TERCA,
        this.DAYS.QUARTA,
        this.DAYS.QUINTA,
        this.DAYS.SEXTA,
        this.DAYS.SABADO,
      ]
      let data = new Date()
      let newHeader = []
      semana.forEach((dia) => {
        let diff = data.getUTCDay() - dia
        let nova_data = new Date(data.getTime() - TIME_DAYS(diff))
        let new_data = nova_data.toISOString().split('T')[0].split('-')
        let new_text = new_data.reverse().join('/')
        newHeader.push({
          text: new_text,
          value: new_text,
        })
      })
      this.changeHeadersDay(newHeader)
    },
    async atualizarEstrutura() {
      this.programacoesClienteEstruturada = []
      await this.atualizarListaProgramacoesCliente()
      this.estruturaProgramacoesCliente()
    },
    estruturaProgramacoesCliente() {
      let estrutura = []
      let scope = this
      this.programacoesCliente.forEach((element) => {
        element.programacao_regiao.programacao_fornecedor.tipos_frete.forEach(
          (elem) => {
            let i = estrutura.findIndex(
              (el) =>
                el.transportadora.public_id ==
                  element.transportadora.public_id &&
                el.programacao_regiao.cliente.public_id ==
                  element.programacao_regiao.cliente.public_id &&
                el.programacao_regiao.programacao_fornecedor.regiao.public_id ==
                  element.programacao_regiao.programacao_fornecedor.regiao
                    .public_id &&
                el.programacao_regiao.programacao_fornecedor.programacao_unidade
                  .terminal.public_id ==
                  element.programacao_regiao.programacao_fornecedor
                    .programacao_unidade.terminal.public_id &&
                el.programacao_regiao.programacao_fornecedor.programacao_unidade
                  .produto.id ==
                  element.programacao_regiao.programacao_fornecedor
                    .programacao_unidade.produto.id &&
                el.programacao_regiao.programacao_fornecedor.programacao_unidade
                  .sub_produto.id ==
                  element.programacao_regiao.programacao_fornecedor
                    .programacao_unidade.sub_produto.id &&
                el.programacao_regiao.programacao_fornecedor.programacao_unidade
                  .fornecedor.public_id ==
                  element.programacao_regiao.programacao_fornecedor
                    .programacao_unidade.fornecedor.public_id &&
                el.tipo_frete.id == elem.id
            )
            if (i == -1) {
              // CRIANDO ESTRUTURA
              let nova_estrutura = { ...this.estrutura_exemplo }
              nova_estrutura.programacao_regiao = element.programacao_regiao
              nova_estrutura.tipo_frete = elem
              nova_estrutura.transportadora = element.transportadora
              nova_estrutura.status = [
                {
                  descricao: 'Cotas Liberadas',
                },
                {
                  descricao: 'Cotas Distribuídas',
                },
                {
                  descricao: 'Agendadas',
                },
                {
                  descricao: 'Na triagem',
                },
                {
                  descricao: 'Atendidas',
                },
                {
                  descricao: 'Disponível',
                },
              ]
              i = estrutura.push(nova_estrutura) - 1
              // i = estrutura.findIndex(
              //   (el) =>
              //     el.regiao == element.regiao &&
              //     el.programacao_unidade.terminal ==
              //       element.programacao_unidade.terminal &&
              //     el.programacao_unidade.produto ==
              //       element.programacao_unidade.produto &&
              //     el.programacao_unidade.fornecedor ==
              //       element.programacao_unidade.fornecedor &&
              //     el.tipo_frete == elem
              // )
            }
            let nova_data = new Date(
              element.programacao_regiao.programacao_fornecedor.programacao_unidade.data_inicio
            )
            let new_data = nova_data.toISOString().split('T')[0].split('-')
            let data = new_data.reverse().join('/')
            // Cotas Liberadas
            estrutura[i].status[0]
            estrutura[i].status[0][data] = estrutura[i].status[0][data] || {
              qt_peso: 0,
              qt_caminhoes: 0,
            }
            estrutura[i].status[0][data].qt_peso += element.qt_peso * 1 || 0
            estrutura[i].status[0][data].qt_caminhoes +=
              element.qt_caminhoes * 1 || 0
            // Cotas Distribuídas
            estrutura[i].status[1]
            estrutura[i].status[1][data] = estrutura[i].status[1][data] || {
              qt_peso: 0,
              qt_caminhoes: 0,
            }
            estrutura[i].status[1][data].qt_peso +=
              scope.quantidade_distribuida_peso(element) || 0
            estrutura[i].status[1][data].qt_caminhoes +=
              scope.quantidade_distribuida_caminhoes(element) || 0
            // Agendadas
            estrutura[i].status[2]
            estrutura[i].status[2][data] = estrutura[i].status[2][data] || {
              qt_peso: 0,
              qt_caminhoes: 0,
            }
            estrutura[i].status[2][data].qt_peso +=
              scope.quantidade_agendada_peso(element) || 0
            estrutura[i].status[2][data].qt_caminhoes +=
              scope.quantidade_agendada_caminhoes(element) || 0
            // Na triagem
            estrutura[i].status[3]
            estrutura[i].status[3][data] = estrutura[i].status[3][data] || {
              qt_peso: 0,
              qt_caminhoes: 0,
            }
            // Atendidas
            estrutura[i].status[4]
            estrutura[i].status[4][data] = estrutura[i].status[4][data] || {
              qt_peso: 0,
              qt_caminhoes: 0,
            }
            // Disponível
            estrutura[i].status[5]
            estrutura[i].status[5][data] = estrutura[i].status[5][data] || {
              qt_peso: 0,
              qt_caminhoes: 0,
            }
            estrutura[i].status[5][data].qt_peso +=
              scope.quantidade_restante_peso(element) || 0
            estrutura[i].status[5][data].qt_caminhoes +=
              scope.quantidade_restante_caminhoes(element) || 0
          }
        )
      })
      this.programacoesClienteEstruturada = estrutura
    },
    changeHeadersDay(newHeadersDay) {
      this.headersDay = [this.headersDay[0]].concat(newHeadersDay)
    },
  },
}
</script>
