<template>
  <div>
    <v-sheet tile height="54" class="d-flex">
      <v-btn outlined class="ma-2" color="grey darken-2" @click="setToday">
        Hoje
      </v-btn>
      <v-autocomplete
        v-model="type"
        :items="types"
        item-text="text"
        item-value="value"
        dense
        outlined
        hide-details
        class="ma-2"
        label="Tipos"
      ></v-autocomplete>
      <v-autocomplete
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="Dias da Semana"
        class="ma-2"
      ></v-autocomplete>
      <v-btn
        outlined
        class="ma-2"
        color="grey darken-2"
        @click="atualizarListaProgramacoesTransportadora()"
        >Atualizar
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="showEvent"
        @change="getEvents"
      >
        <template v-slot:event="{ event, timed, eventSummary }">
          <div class="v-event-draggable" v-html="eventSummary()"></div>
          <div class="ma-2">
            <b>Restante:</b>
             <table
            style="width: 90%; background: white; color: black; margin: 10px"
            border="1px"
          >
            <thead>
              <tr>
                <th style="padding: 1px">Designado</th>
                <th style="padding: 1px">Atendida</th>
                <th style="padding: 1px">Restante</th>
                <th style="padding: 1px">Performace</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="event.item.qt_caminhoes > 0" style="padding: 1px">
                  {{ event.item.qt_caminhoes }}
                </td>
                <td v-if="event.item.qt_peso > 0" style="padding: 1px">
                  {{ event.item.qt_peso }} TON
                </td>

                <td v-if="event.item.qt_caminhoes > 0" style="padding: 1px">
                  {{ quantidade_atendida(event.item) }}
                </td>
                <td v-if="event.item.qt_caminhoes > 0" style="padding: 1px">
                  {{ quantidade_restante_caminhoes(event.item) }}
                </td>
                <td v-if="event.item.qt_caminhoes > 0" style="padding: 1px">
                  {{ performace_caminhoes(event.item) }}
                </td>

                <td v-if="event.item.qt_peso > 0" style="padding: 1px">
                  {{ quantidade_agendada_peso(event.item) }} TON
                </td>
                <td v-if="event.item.qt_peso > 0" style="padding: 1px">
                  {{ quantidade_restante_peso(event.item) }} TON
                </td>
                <td v-if="event.item.qt_peso > 0" style="padding: 1px">
                  {{ performace_caminhoes_peso(event.item) }} TON
                </td>
              </tr>
            </tbody>
          </table>
            <hr />
          </div>
          <ul>
            <li class="font-weight-black">
              {{
                event.item.programacao_regiao.programacao_fornecedor
                  .programacao_unidade.tipo_operacao
                  ? event.item.programacao_regiao.programacao_fornecedor
                      .programacao_unidade.tipo_operacao.descricao
                  : 'Sem operação!'
              }}
            </li>

            <li>
              {{
                event.item.programacao_regiao.programacao_fornecedor
                  .programacao_unidade.fornecedor
                  ? event.item.programacao_regiao.programacao_fornecedor
                      .programacao_unidade.fornecedor.trade_name
                  : 'Sem fornecedor!'
              }}
            </li>
            <li
              v-if="
                event.item.programacao_regiao.programacao_fornecedor
                  .programacao_unidade.terminal
              "
            >
              {{
                event.item.programacao_regiao.programacao_fornecedor
                  .programacao_unidade.terminal.trade_name
              }}
            </li>
            <li
              v-if="
                event.item.programacao_regiao.programacao_fornecedor
                  .programacao_unidade.produto
              "
            >
              {{
                event.item.programacao_regiao.programacao_fornecedor
                  .programacao_unidade.produto.descricao
              }}
            </li>
            <li
              v-if="
                event.item.programacao_regiao.programacao_fornecedor
                  .programacao_unidade.sub_produto
              "
            >
              {{
                event.item.programacao_regiao.programacao_fornecedor
                  .programacao_unidade.sub_produto.descricao
              }}
            </li>
            <li>Peso: {{ event.item.qt_peso ? event.item.qt_peso : '0' }}</li>
            <li>
              Caminhões:
              {{ event.item.qt_caminhoes ? event.item.qt_caminhoes : '0' }}
            </li>
          </ul>
        </template>
      </v-calendar>
    </v-sheet>
    <v-dialog
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-container>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <form>
              <v-card-title>
                <span class="headline">Programação Transportadora</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="3">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .proprietario
                        "
                        label="Gate/Unidade"
                        :items="[
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .proprietario,
                        ]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        return-object
                        prepend-icon="mdi-boom-gate"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .fornecedor
                        "
                        label="Fornecedor"
                        :items="[
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .fornecedor,
                        ]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        return-object
                        prepend-icon="mdi-equalizer"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .tipo_operacao
                        "
                        label="Tipo de Operação"
                        :items="[
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .tipo_operacao,
                        ]"
                        item-text="descricao"
                        item-value="id"
                        return-object
                        prepend-icon="mdi-clipboard-flow-outline"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade.terminal
                        "
                        label="Terminal"
                        :items="[
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .terminal,
                        ]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        return-object
                        prepend-icon="mdi-alpha-t-box-outline"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-divider class="my-5"></v-divider>
                  <v-row>
                    <v-col class="pt-0" cols="12" sm="3">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .tipo_controle
                        "
                        label="Tipo de controle"
                        :items="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .tipo_controle
                            ? [
                                programacaoCliente.programacao_regiao
                                  .programacao_fornecedor.programacao_unidade
                                  .tipo_controle,
                              ]
                            : []
                        "
                        item-text="descricao"
                        item
                        prepend-icon="mdi-clipboard-alert-outline"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="pt-0" cols="12" sm="9">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .modalidades
                        "
                        label="Tipos de Veículo"
                        :items="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .modalidades
                        "
                        item-text="descricao"
                        item-value="id"
                        multiple
                        chips
                        prepend-icon="mdi-truck-outline"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="pt-0" cols="12" sm="12">
                      <v-text-field
                        readonly
                        v-model="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .observacao
                        "
                        :value="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .observacao
                        "
                        label="Observação"
                        prepend-icon="mdi-note-text-outline"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" lg="3">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade.produto
                        "
                        label="Produto"
                        :items="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade.produto
                            ? [
                                programacaoCliente.programacao_regiao
                                  .programacao_fornecedor.programacao_unidade
                                  .produto,
                              ]
                            : []
                        "
                        item-text="descricao"
                        item-value="id"
                        return-object
                        prepend-icon="mdi-alpha-p-box-outline"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .sub_produto
                        "
                        :items="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.programacao_unidade
                            .sub_produto
                            ? [
                                programacaoCliente.programacao_regiao
                                  .programacao_fornecedor.programacao_unidade
                                  .sub_produto,
                              ]
                            : []
                        "
                        item-text="descricao"
                        label="Sub Produto"
                        item-value="id"
                        return-object
                        prepend-icon="mdi-alpha-p-circle-outline"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="12" md="3" lg="3">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.regiao
                        "
                        label="Região"
                        :items="[
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.regiao,
                        ]"
                        item-text="sigla"
                        item-value="public_id"
                        prepend-icon="mdi-home-city"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="3" lg="3">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.tipos_frete
                        "
                        :items="
                          programacaoCliente.programacao_regiao
                            .programacao_fornecedor.tipos_frete
                        "
                        item-text="descricao"
                        item-value="id"
                        label="Tipo de Frete"
                        prepend-icon="mdi-truck"
                        chips
                        multiple
                        return-object
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="4" lg="4">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="programacaoCliente.programacao_regiao.cliente"
                        label="Cliente"
                        :items="[programacaoCliente.programacao_regiao.cliente]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        prepend-icon="mdi-account"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4">
                      <v-autocomplete
                        readonly
                        v-model="programacaoCliente.programacao_regiao.pedidos"
                        :items="programacaoCliente.programacao_regiao.pedidos"
                        item-text="numero_pedido"
                        item-value="public_id"
                        label="Pedidos"
                        prepend-icon="mdi-ticket-confirmation-outline"
                        chips
                        multiple
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" lg="4" md="4">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="programacaoCliente.transportadora"
                        label="Transportadora"
                        :items="[programacaoCliente.transportadora]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        prepend-icon="mdi-account"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" lg="6">
                      <div class="mt-5 d-flex justify-space-around">
                        <label>Data inicial:</label>
                        <input
                          type="datetime-local"
                          readonly
                          name="datetime"
                          :value="
                            date2iso(
                              new Date(
                                programacaoCliente.programacao_regiao.programacao_fornecedor.programacao_unidade.data_inicio
                              )
                            )
                          "
                        />
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <div class="mt-5 d-flex justify-space-around">
                        <label>Data final:</label>
                        <input
                          type="datetime-local"
                          readonly
                          name="datetime"
                          :value="
                            date2iso(
                              new Date(
                                programacaoCliente.programacao_regiao.programacao_fornecedor.programacao_unidade.data_fim
                              )
                            )
                          "
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col cols="12" class="d-flex justify-space-between">
                    <div>
                      <router-link
                        v-if="
                          !(
                            qt_peso(programacaoCliente) == 0 &&
                            qt_caminhoes(programacaoCliente) == 0
                          ) &&
                          editavel &&
                          notSuspended &&
                          !invalid
                        "
                        :to="{
                          name: 'NovoAgendamento',
                          params: {
                            ...paramsToAgendamento(programacaoCliente),
                          },
                        }"
                      >
                        <v-btn color="primary" small>Novo Agendamento</v-btn>
                      </router-link>
                      <v-btn
                        small
                        class="mx-3"
                        @click="detalhesDistribuicao(programacaoCliente)"
                      >
                        Agendamentos
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-container>
      </v-card>
      <v-overlay :value="carregaDetalhes">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-dialog>
    <v-dialog v-model="detalhar">
      <detalhes-programacao
        :detalhes="detalhes"
        :title="title"
        :headers="headersDetalhes"
        :download="download"
        @close="closeModal()"
      />
    </v-dialog>
  </div>
</template>
<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}
.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}
.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }
  &:hover::after {
    display: block;
  }
}
</style>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { DAYS, date2iso } from '@/mixins/days.js'
import {
  paramsToAgendamento,
  contabilizadores,
  pesquisa,
} from '@/mixins/cotas.js'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import DetalhesProgramacao from '@/components/patio/Programacao/Detalhes/DetalhesProgramacao'

export default {
  name: 'CalendarioProgramacaoTransportadora',
  props: ['params'],
  mixins: [paramsToAgendamento, contabilizadores, pesquisa],
  components: {
    DetalhesProgramacao,
  },
  data() {
    return {
      detalhes: [],
      title: {},
      download: {
        type: '',
        public_id: '',
      },
      detalhar: false,
      headersDetalhes: [
      {
          text: 'Veículo',
          value: 'veiculo',
        },
        { 
          text: 'Status', 
          value: 'status' 
        },
        {
          text: 'Data da Cota',
          value: 'data_cota',
        },
        {
          text: 'Triado Em',
          value: 'triado_em',
        },
        {
          text: 'Liberado Em',
          value: 'saida_do_patio',
        },
        {
          text: 'Tempo em patio',
          value: 'tempo_em_patio',
        },
        {
          text: 'Finalizado Em',
          value: 'chegada_terminal',
        },
        {
          text: 'Cliente',
          value: 'cliente',
        },
        {
          text: 'Produto',
          value: 'produto',
        },
        {
          text: 'Sub Produto',
          value: 'sub_produto',
        },
        {
          text: 'Nota fiscal',
          value: 'num_nota_fiscal',
        },
        {
          text: 'Quantidade (KG)',
          value: 'quantidade',
        },
        {
          text: 'Embalagem',
          value: 'embalagem',
        },
        {
          text: 'Modalidade',
          value: 'modalidade',
        },
        {
          text: 'Eixos',
          value: 'eixos',
        },
      ],
      carregaDetalhes: false,
      type: 'week',
      types: [
        { text: 'Mês', value: 'month' },
        { text: 'Semana', value: 'week' },
        { text: 'Dia', value: 'day' },
      ],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [
        DAYS.DOMINGO,
        DAYS.SEGUNDA,
        DAYS.TERCA,
        DAYS.QUARTA,
        DAYS.QUINTA,
        DAYS.SEXTA,
        DAYS.SABADO,
      ],
      weekdays: [
        {
          text: 'Domingo - Sábado',
          value: [
            DAYS.DOMINGO,
            DAYS.SEGUNDA,
            DAYS.TERCA,
            DAYS.QUARTA,
            DAYS.QUINTA,
            DAYS.SEXTA,
            DAYS.SABADO,
          ],
        },
        {
          text: 'Segunda - Sexta',
          value: [
            DAYS.SEGUNDA,
            DAYS.TERCA,
            DAYS.QUARTA,
            DAYS.QUINTA,
            DAYS.SEXTA,
          ],
        },
      ],
      value: '',
      events: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      colors: [
        'blue',
        'indigo',
        'deep-purple',
        'cyan',
        'green',
        'orange',
        'grey darken-1',
      ],
      selectFornecedor: [],
      selectProduto: [],
      selectSubProduto: [],
      selectTerminal: [],
      replicar_possibilidades: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      replicar: [],
      tab: null,
      editedIndex: -1,
      defaultItem: {
        public_id: '',
        regiao: null,
        tipos_frete: null,
        qt_peso: 0,
        qt_caminhoes: 0,
      },
      editedItem: {
        public_id: '',
        regiao: null,
        tipos_frete: null,
        qt_peso: 0,
        qt_caminhoes: 0,
      },
    }
  },
  computed: {
    ...mapState('patio', ['programacaoCliente', 'programacoesCliente']),
    ...mapState('auth', ['user', 'empresaAtual']),
    ...mapState('transportadoras', ['transportadoras']),
    editavel() {
      if (this.programacaoCliente) {
        if (!this.programacaoCliente.programacao_regiao) {
          return false
        }
        if (
          new Date(
            this.programacaoCliente.programacao_regiao.programacao_fornecedor.programacao_unidade.data_fim
          ) > new Date()
        ) {
          return true
        }
      }
      return false
    },
    suspendivel() {
      if (this.editedIndex >= 0) {
        if (new Date(this.editedItem.data_fim) > new Date()) {
          return true
        }
      }
      return false
    },
    notSuspended() {
      if (this.programacaoCliente) {
        return (
          this.programacaoCliente.programacao_regiao.programacao_fornecedor
            .programacao_unidade.suspendido == 0
        )
      }
      return true
    },
    allowedToAdd() {
      return this.empresaAtual.gate
    },
    formTitle() {
      return this.editedIndex === -1
        ? 'Distribuir nova cota'
        : 'Editar cota distribuida'
    },
    permission() {
      return Sequences.CotasTransportadora.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    this.date2iso = date2iso
    // await this.atualizarListaProgramacoesTransportadora()
  },
  watch: {},
  methods: {
    ...mapActions('patio', ['getProgramacoesCliente']),
    ...mapMutations('patio', ['SET_PROGRAMACAO_CLIENTE']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapActions('programacao', ['getProgramacaoDetalhe']),
    async closeModal(){
      this.detalhar = false
      this.detalhes = []
      this.title = {}
    },
    async detalhesDistribuicao(item) {
      try {
        this.carregaDetalhes = true
        const data = await this.getProgramacaoDetalhe({
          type: 'Cliente',
          public_id: item.public_id,
        })
        this.detalhes = data.agendamentos
        this.title = data.title
        this.download.type = 'Cliente'
        this.download.public_id = item.public_id
        this.detalhar = true
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Erro ao buscar detalhes. Tente novamente ou contacte o suporte.'
        )
      } finally {
        this.carregaDetalhes = false
      }
    },
    close() {
      this.selectedOpen = false
    },
    async atualizarListaProgramacoesTransportadora(params = null) {
      try {
        if (params == null) {
          params = this.params
        }
        if (this.empresaAtual.representantes.length > 0) {
          const selectedAtLeastOneRegion = params?.regiao?.public_id
          if (!selectedAtLeastOneRegion) {
            alert('Necessário selecionar região para buscar os dados.')
            return
          }
        }
        let filtros = this.pesquisa(params)
        if (!filtros.data_inicio) {
          filtros.data_inicio = new Date(
            new Date(new Date().setHours(0, 0, 0, 0)).setDate(
              new Date().getDate() - new Date().getDay()
            )
          )
            .toISOString()
            .split('T')[0]
        }
        await this.getProgramacoesCliente(filtros)
        this.events = []
        this.getEvents()
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não possui acesso as programações.')
        } else {
          this.errorMessage(error.response.data)
        }
      }
    },
    createName(programacao) {
      let name = ''
      name += programacao.programacao_regiao.programacao_fornecedor
        .programacao_unidade.fornecedor
        ? programacao.programacao_regiao.programacao_fornecedor
            .programacao_unidade.fornecedor.trade_name
          ? programacao.programacao_regiao.programacao_fornecedor
              .programacao_unidade.fornecedor.trade_name
          : programacao.programacao_regiao.programacao_fornecedor
              .programacao_unidade.fornecedor.fornecedor_trade_name
        : ''
      if (
        programacao.programacao_regiao.programacao_fornecedor
          .programacao_unidade.produto
      ) {
        name +=
          ' - ' +
          programacao.programacao_regiao.programacao_fornecedor
            .programacao_unidade.produto.descricao
      }
      if (
        programacao.programacao_regiao.programacao_fornecedor
          .programacao_unidade.sub_produto
      ) {
        name +=
          ' - ' +
          programacao.programacao_regiao.programacao_fornecedor
            .programacao_unidade.sub_produto.descricao
      }
      if (
        programacao.programacao_regiao.programacao_fornecedor
          .programacao_unidade.qt_caminhoes
      ) {
        name +=
          ' - ' +
          programacao.programacao_regiao.programacao_fornecedor
            .programacao_unidade.qt_caminhoes +
          ' Cam.'
      }
      if (
        programacao.programacao_regiao.programacao_fornecedor
          .programacao_unidade.qt_peso
      ) {
        name +=
          ' - ' +
          programacao.programacao_regiao.programacao_fornecedor
            .programacao_unidade.qt_peso +
          ' Ton.'
      }
      return name
    },
    getEvents() {
      this.programacoesCliente.forEach((element) => {
        let i = this.events.findIndex(
          (event) => event.item.public_id == element.public_id
        )
        let start = new Date(
          element.programacao_regiao.programacao_fornecedor.programacao_unidade.data_inicio
        )
        let end = new Date(
          element.programacao_regiao.programacao_fornecedor.programacao_unidade.data_fim
        )
        if (i == -1) {
          this.events.push({
            item: element,
            name: this.createName(element),
            color:
              element.programacao_regiao.programacao_fornecedor
                .programacao_unidade.suspendido == 1
                ? 'red'
                : element.programacao_regiao.programacao_fornecedor
                    .programacao_unidade.tipo_operacao
                ? element.programacao_regiao.programacao_fornecedor
                    .programacao_unidade.tipo_operacao.id == 1
                  ? this.colors[4]
                  : this.colors[0]
                : this.colors[0],
            start: start.getTime(),
            end: end.getTime(),
            timed: true,
          })
        } else {
          this.events[i] = {
            item: element,
            name: this.createName(element),
            color:
              element.programacao_regiao.programacao_fornecedor
                .programacao_unidade.suspendido == 1
                ? 'red'
                : element.programacao_regiao.programacao_fornecedor
                    .programacao_unidade.tipo_operacao
                ? element.programacao_regiao.programacao_fornecedor
                    .programacao_unidade.tipo_operacao.id == 1
                  ? this.colors[4]
                  : this.colors[0]
                : this.colors[0],
            start: start.getTime(),
            end: end.getTime(),
            timed: true,
          }
        }
      })
    },
    setToday() {
      this.value = ''
    },
    showEvent({ nativeEvent, event }) {
      if (this.canUpdate) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          this.SET_PROGRAMACAO_CLIENTE(event.item)
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.selectedOpen = true))
          )
        }
        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }
        nativeEvent.stopPropagation()
      }
    },
    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16)
      const r = (rgb >> 16) & 0xff
      const g = (rgb >> 8) & 0xff
      const b = (rgb >> 0) & 0xff
      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event.color
    },
  },
}
</script>
