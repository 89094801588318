<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Programação por Transportadora">
          <v-row class="grey lighten-4" v-if="canList">
            <v-col cols="3" sm="3">
              <pesquisa-programacao-transportadora
                ref="pesquisa"
                @pesquisar="pesquisar"
                @prev="calendarPrev"
                @next="calendarNext"
              />
            </v-col>
            <v-col cols="9" sm="9" class="grey lighten-5">
              <calendario-programacao-transportadora
                ref="calendario"
                v-bind:params="params"
              >
              </calendario-programacao-transportadora>
            </v-col>
          </v-row>
          <v-row v-if="!canList">
            <v-col cols="12" sm="12" align="center">
              <span class="red--text"
                >Você não tem permissão para visualizar as informações desta
                tela</span
              >
            </v-col>
          </v-row>
        </BaseCard>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" align="center">
        <v-btn color="primary" small @click="verRelatorio">
          Visualizar distribuição
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <cotas-programacao-transportadora
          ref="cotas"
          v-show="relatorio"
          v-bind:calendario="calendario"
          v-bind:params="params"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import PesquisaProgramacaoTransportadora from '@/components/patio/Programacao/Transportadora/Pesquisa.vue'
import CalendarioProgramacaoTransportadora from '@/components/patio/Programacao/Transportadora/Calendario.vue'
import CotasProgramacaoTransportadora from '@/components/patio/Programacao/Transportadora/Cotas.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'

import { DAYS, TIME_DAYS } from '@/mixins/days.js'
export default {
  data() {
    return {
      showForm: false,
      editarTransportadora: false,
      relatorio: false,
      page: {
        title: 'Gerenciamento de programação por Transportadora',
      },
      breadcrumbs: [
        {
          text: 'Programação por Transportadora',
          disabled: true,
        },
      ],
      params: {},
    }
  },
  components: {
    PesquisaProgramacaoTransportadora,
    CalendarioProgramacaoTransportadora,
    CotasProgramacaoTransportadora,
  },
  computed: {
    calendario: function () {
      return this.$refs.calendario
    },

    canList() {
      return this.$canDo(
        BasicPermissions.VISUALIZAR,
        Sequences.CotasTransportadora.toString()
      )
    },
  },
  async created() {
    this.DAYS = DAYS
  },
  mounted() {
    this.mesAno()
    this.changeHeadersDay()
  },
  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async verRelatorio() {
      if (Object.entries(this.params).length == 0) {
        this.errorMessage('Pesquise alguma informação primeiro!')
        return
      }
      await this.$refs.cotas.atualizarEstrutura(this.params)
      this.relatorio = true
    },
    mesAno() {
      window.calendar = { ...this.$refs.calendario.$refs.calendar }
      let meses = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ]
      this.$refs.pesquisa.mes =
        meses[this.$refs.calendario.$refs.calendar.lastStart.month - 1]
      this.$refs.pesquisa.ano = this.$refs.calendario.$refs.calendar.lastStart.year
    },
    changeHeadersDay() {
      let calendar = this.$refs.calendario.$refs.calendar
      let semana = [
        this.DAYS.DOMINGO,
        this.DAYS.SEGUNDA,
        this.DAYS.TERCA,
        this.DAYS.QUARTA,
        this.DAYS.QUINTA,
        this.DAYS.SEXTA,
        this.DAYS.SABADO,
      ]
      let start = calendar.lastStart
      let data = new Date(start.date)
      let newHeader = []
      semana.forEach((dia) => {
        let diff = data.getUTCDay() - dia
        let nova_data = new Date(data.getTime() - TIME_DAYS(diff))
        let new_data = nova_data.toISOString().split('T')[0].split('-')
        let new_text = new_data.reverse().join('/')
        newHeader.push({
          text: new_text,
          value: new_text,
        })
      })
      this.$refs.cotas.changeHeadersDay(newHeader)
    },
    async calendarPrev() {
      await this.$refs.calendario.$refs.calendar.prev()
      this.mesAno()
      this.changeHeadersDay()
    },
    async calendarNext() {
      await this.$refs.calendario.$refs.calendar.next()
      this.mesAno()
      this.changeHeadersDay()
    },
    getParams() {
      return this.params
    },
    async pesquisar(params) {
      this.params = params
      await this.$refs.calendario.atualizarListaProgramacoesTransportadora(
        params
      )
      // if (this.relatorio) {
      //   await this.$refs.cotas.atualizarEstrutura(params)
      // }
    },
  },
}
</script>

<style></style>
